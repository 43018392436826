import React, {useEffect, Fragment, useState} from 'react';
import { Link } from 'react-router-dom';
import apiClient from "../../http-common";
import { useQuery } from "react-query";

const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
const getDate = (date) => new Date(date).toDateString();
const addressText = (address) => {
    let last6 = address.slice(address.length - 11);
    let first3 = address.substring(0,3);
    return first3+'XXX'+last6;
}

const Trades = () => {
    const { uniqueNamesGenerator, names, NumberDictionary } = require('unique-names-generator');
    const shuffleItems  = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex != 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }

    const initialAnalysis = {
        Investments: [],        
        
    }
    const [analysis, setAnalysis] = useState(initialAnalysis);
    const { Investments } = analysis;

    const { isLoading: isLoadingAnalysis, refetch: getAnalysis } = useQuery(
        "account-analysis",
        async () => {
          return await apiClient.get("/api/analysis");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setAnalysis({...analysis, 
                    Investments: res.data.investments,                    
                });
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
           if(window && window.init_template){
            window.init_template();
        }  
        getAnalysis();


       let regenerateor =  setInterval(() => {
            
            if (Investments.length > 0) {
              setAnalysis({...analysis, investments: shuffleItems(Investments)})
            }
            
          }, 2500);

          return () => {
            clearInterval(regenerateor);
        };

      }, [isLoadingAnalysis, Investments]);
      

  return (
    <div className='my-4'>
        <div className="content px-1 mx-0">
            <div className="d-flex">
                <div className="align-self-center">
                    <h3 className="font-16 mb-2">Trading activities</h3>
                </div>
                <div className="align-self-center ms-auto">
                    <Link to="/" className="font-12 pt-1 text-info">
                        {isLoadingAnalysis ? <span>updating...</span>: <span>updated now</span>}
                    </Link>
                </div>
            </div>
        </div>
        <div className="mx-0">
            <div className="card pt-2 px-0 rounded-2 shadow-sm">
                <div className="content mt-0 mb-0">
                {Investments.length > 0 &&
                Investments.map(Investment => (  
                <Fragment key={Investment.id}>          
                    {Investment.status === 'pending' && 
                    <Link to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 bg-success shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>done_all</i>
                            </span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{uniqueNamesGenerator({ dictionaries: [names] })}</h5>
                            <p className="mb-0 font-11 opacity-50">{new Date().toLocaleTimeString()}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-green-dark">{getAmount(NumberDictionary.generate({ min: 100, max: 1000000 }))}</h4>
                            <p className="mb-0 font-12 color-green-dark">Status: Approved</p>
                        </div>
                    </Link>
                    }
                    {Investment.status === 'waiting approval' && 
                    <Link to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 bg-success shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>done_all</i>
                            </span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{uniqueNamesGenerator({ dictionaries: [names] })}</h5>
                            <p className="mb-0 font-11 opacity-50">{new Date().toLocaleTimeString()}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-green-dark">{getAmount(NumberDictionary.generate({ min: 100, max: 1000000 }))}</h4>
                            <p className="mb-0 font-12 color-green-dark">Status: Approved</p>
                        </div>
                    </Link>
                    }
                    {Investment.status === 'approved' && 
                    <Link to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 bg-success shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>done_all</i>
                            </span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{uniqueNamesGenerator({ dictionaries: [names] })}</h5>
                            <p className="mb-0 font-11 opacity-50">{new Date().toLocaleTimeString()}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-green-dark">{getAmount(NumberDictionary.generate({ min: 100, max: 1000000 }))}</h4>
                            <p className="mb-0 font-12 color-green-dark">Status: Approved</p>
                        </div>
                    </Link>
                    }  
                    {Investment.status === 'completed' && 
                    <Link to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 bg-success shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>done_all</i>
                            </span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{uniqueNamesGenerator({ dictionaries: [names] })}</h5>
                            <p className="mb-0 font-11 opacity-50">{new Date().toLocaleTimeString()}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-green-dark">{getAmount(NumberDictionary.generate({ min: 100, max: 1000000 }))}</h4>
                            <p className="mb-0 font-12 color-green-dark">Status: Approved</p>
                        </div>
                    </Link>
                    } 
                </Fragment> 
                ))
                }                             
                </div>
            </div>
        </div>
    </div>
  )
}

export default Trades
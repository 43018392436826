import React, {useEffect, useState, Fragment} from 'react'
import { Link } from "react-router-dom";
import {investmentsHistory} from '../Request/Histories';

const InvestmentHistory = () => {
    
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
    const getDate = (date) => new Date(date).toDateString();
    const addressText = (address) => {
        let last6 = address.slice(address.length - 11);
        let first3 = address.substring(0,3);
        return first3+'XXX'+last6;
    }

    const [investments, setInvestments] = useState(investmentsHistory());

    const newInvestmentsHistory = () => {
        let accountHistory = [];
        let storedHistory = localStorage.getItem('binaryfair-account-histories');
        if (storedHistory !== null) {
            let data = JSON.parse(storedHistory);
            accountHistory = data.investments;
        }
        return accountHistory;
    }

    useEffect(() => {
        
       window.addEventListener('updatedStorage', function (e) {
        setInvestments(newInvestmentsHistory());
  }, false);        
    });

  return (
    <div className=''>
         <div className="content my-0 px-1">
            <div className="d-flex">
                <div className="align-self-center">
                    <h3 className="font-16 mb-2">Account activity</h3>
                </div>
                <div className="align-self-center ms-auto">
                    <Link to="/" className="font-12 pt-1 text-info">
                        <span>updated now</span>
                    </Link>
                </div>
            </div>
        </div>
        <div className="row g-2 px-sm-3 px-0">
            
        
                {investments.length > 0 &&
                investments.map(Investment => (  
                    <Fragment key={Investment.id} >
                        {Investment.status === 'pending' && 
                        <div  className="card pt-2 my-2 px-0 rounded-0 shadow-sm col-12 col-md-4">
                        <div className="content mt-0 mb-0">
                        <Link to="#" className="d-flex pb-3">
                            <div className="align-self-center">
                                <span className="icon rounded-s me-2 gradient-yellow shadow-bg shadow-bg-xs">
                                <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>pending_actions</i>
                                </span>
                            </div>
                            <div className="align-self-center ps-1">
                                <h5 className="pt-1 mb-n1">{getAmount(Investment.amount)}</h5>
                                <p className="mb-0 font-11 opacity-50">{getDate(Investment.created_at)}</p>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h4 className="pt-1 mb-n1 color-yellow-dark">Yield: {getAmount(Investment.earning)}</h4>
                                <p className="mb-0 font-12 color-yellow-dark">Percentage: {Investment.earned_percentage}%</p>
                            </div>
                        </Link>
                        </div>
                        </div>
                        } 
                    </Fragment>
                ))
                }   


                {investments.length > 0 &&
                investments.map(Investment => ( 
                    <Fragment key={Investment.id}>
                        {Investment.status === 'waiting approval' && 
                        <div  className="card pt-2 my-2 px-0 rounded-0 shadow-sm col-12 col-md-4">
                        <div className="content mt-0 mb-0">
                        <Link  to="#" className="d-flex pb-3">
                            <div className="align-self-center">
                                <span className="icon rounded-s me-2 gradient-yellow shadow-bg shadow-bg-xs">
                                <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>pending_actions</i>
                                </span>
                            </div>
                            <div className="align-self-center ps-1">
                                <h5 className="pt-1 mb-n1">{getAmount(Investment.amount)}</h5>
                                <p className="mb-0 font-11 opacity-50">{getDate(Investment.created_at)}</p>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h4 className="pt-1 mb-n1 color-yellow-dark">Yield: {getAmount(Investment.earning)}</h4>
                                <p className="mb-0 font-12 color-yellow-dark">Percentage: {Investment.earned_percentage}%</p>
                            </div>
                        </Link>
                        </div>
                        </div>
                    } 
                    </Fragment>
                ))
                } 


            {investments.length > 0 &&
                investments.map(Investment => ( 
                <Fragment key={Investment.id}>
                    {Investment.status === 'approved' && 
                    <div  className="card pt-2 my-2 px-0 rounded-0 shadow-sm col-12 col-md-4">
                    <div className="content mt-0 mb-0">
                    <Link  to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 bg-success shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>task_alt</i>
                            </span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{getAmount(Investment.amount)}</h5>
                            <p className="mb-0 font-11 opacity-50">{getDate(Investment.created_at)}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-green-dark">Yield: {getAmount(Investment.earning)}</h4>
                            <p className="mb-0 font-12 color-green-dark">Percentage: {Investment.earned_percentage}%</p>
                        </div>
                    </Link>
                    </div>
                    </div>
                    } 
                </Fragment>
                ))
            } 


            {investments.length > 0 &&
                investments.map(Investment => (  
                <Fragment key={Investment.id}>
                    {Investment.status === 'completed' && 
                    <div  className="card pt-2 my-2 px-0 rounded-0 shadow-sm col-12 col-md-4">
                    <div className="content mt-0 mb-0">
                    <Link  to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-gray shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>done_all</i>
                            </span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{getAmount(Investment.amount)}</h5>
                            <p className="mb-0 font-11 opacity-50">{getDate(Investment.created_at)}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-gray-dark">Yield: {getAmount(Investment.earning)}</h4>
                            <p className="mb-0 font-12 color-gray-dark">Percentage: {Investment.earned_percentage}%</p>
                        </div>
                    </Link>
                    </div>
                    </div>
                    } 
                </Fragment>
                ))
            }
        </div>
        
        </div>
  )
}

export default InvestmentHistory
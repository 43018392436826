import React, {useEffect, useState} from 'react';
import apiClient from "../../http-common";
import { useQuery } from "react-query";

export const AuthChecker = () => {
    
    const { isLoading: isLoadingAuth, refetch: getAuth } = useQuery(
        "auth-check",
        async () => {
          return await apiClient.get("/api/check-auth");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                if (res.data.message === 'authenticated') {
                  localStorage.setItem('delox-user', JSON.stringify(res.data.user));
                }else{
                  localStorage.removeItem('delox-user');
                  localStorage.removeItem('user-type');
                  localStorage.removeItem('binaryfair-account-histories');
                  localStorage.removeItem('binaryfair-wallet-balances'); 
                    window.location.href='/';
                }
            }
          },
          onError: (err) => {
            // 
          },
        }
      );
      useEffect(() => {
        getAuth();
      }, []);

  return (
    <div>

    </div>
  )
}
